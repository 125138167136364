import {loadJS} from "./utils";
import {Lottie} from "../@types/lottie";

class LottieAnimation {
    animation: Lottie;
    isIntersecting: boolean = false;

    constructor(private wrapper: HTMLElement, jsonPath: string, validate: () => void) {
        this.animation = window.bodymovin.loadAnimation({
            wrapper: wrapper,
            animType: 'svg',
            loop: true,
            autoplay: false,
            path: jsonPath
        });
        const placeholder = wrapper.querySelector('.placeholder');
        placeholder?.remove();
        const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    this.isIntersecting = (entry.intersectionRatio > 0.8);
                });
                validate();
            },
            {threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]}
        );
        observer.observe(wrapper);
    }

    play() {
        this.animation.play();
    }

    pause() {
        this.animation.pause();
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const animations: Array<LottieAnimation> = [];

    setTimeout(() => {
        loadJS('lottie_light.min.js', () => {
            Array.from(document.querySelectorAll('.lottie-ani-container'))
                .forEach((element: HTMLElement) => {
                    const jsonPath = element.dataset.lottieJson;
                    if (jsonPath) {
                        const ani = new LottieAnimation(element, jsonPath, () => {
                            const currentAnis = animations.filter(ani => ani.isIntersecting);
                            const current = currentAnis[currentAnis.length - 1];
                            animations.forEach(ani => {
                                if (ani === current) {
                                    ani.play();
                                } else {
                                    ani.pause();
                                }
                            });
                        });
                        animations.push(ani);
                    }
                });
        });
    }, 1000);
});
