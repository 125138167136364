export function loadJS(js: string, callback: () => void) {
    const s = document.createElement('script');
    s.src = `/assets/javascripts/${js}`;
    s.type = 'text/javascript';
    s.async = true;
    s.addEventListener('load', function () {
        callback();
    });
    document.body.appendChild(s);
}

export function getRandomRangeInt(min: number, max: number): number {
    return min + Math.floor(Math.random() * max);
}

export function toggleClass(element: HTMLElement, className: string, remove: boolean) {
    if (remove) {
        element.classList.remove(className);
    } else {
        element.classList.add(className);
    }
}

export const formatMoney = (value: number, decPlaces: number, decSep: string, thouSep: string): string => {
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
    decSep = typeof decSep === 'undefined' ? '.' : decSep;
    thouSep = typeof thouSep === 'undefined' ? ',' : thouSep;
    const sign = value < 0 ? '-' : '';
    const num = parseInt(Math.abs(Number(value) || 0).toFixed(decPlaces));
    const i = String(num);
    let j = i.length;
    j = j > 3 ? (i.length === 6 ? 3 : j % 3) : 0;
    return sign +
        (j ? i.slice(0, j) + thouSep : '') +
        i.slice(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep) +
        (decPlaces ? decSep + Math.abs(value - num).toFixed(decPlaces).slice(2) : '');
};

export function selectOption(selectElement: HTMLSelectElement, value: string | null | undefined) {
    if (value !== undefined && value !== null) {
        const opt = Array.from(selectElement.options).find(o => o.value === value);
        if (opt) {
            opt.selected = true;
        }
    }
}

export function formatCurrency(value: number, currency: 'eur' | 'usd', country: string) {
    const money = formatMoney(value, 2, country === 'de' ? ',' : '.', country === 'de' ? '.' : ',');
    const sign = currency === 'usd' ? '$' : '€';
    if (country === 'de' && currency === 'eur') {
        return `${money}${sign}`;
    } else {
        return `${sign}${money}`;
    }
}

export function trackMatomoFormConversion(id?: string) {
    // Matomo form conversion tracking https://developer.matomo.org/guides/form-analytics/reference
    const paq = window._paq;
    if (paq && id) {
        paq.push(['FormAnalytics::trackFormConversion', '', id]);
    }
}

