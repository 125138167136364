export function supportOutsideCarouselIndicators(carouselId: string, carouselIndicatorClasses: Array<string>): void {
    // carousel indicators outside of bootstrap carousel node are not set active/inactive
    const carouselElement = document.getElementById(carouselId);
    if (!carouselElement) {
        return;
    }
    carouselIndicatorClasses.forEach(carouselIndicatorClass => {
        const homeCarouselIndicators = document.querySelectorAll(`.${carouselIndicatorClass} li`);
        carouselElement.addEventListener('slide.bs.carousel', (event) => {
            const index = (event as unknown as bootstrap.Carousel.Event).to;
            homeCarouselIndicators.forEach((indicator, i) => {
                if (i === index) {
                    indicator.classList.add('active');
                } else {
                    indicator.classList.remove('active');
                }
            });
        });
    });
}
