export class VideoResponser {
    /* https://stackoverflow.com/questions/25907930/chrome-not-respecting-video-source-inline-media-queries
    <video id="intro-video" data-src="/path/default.mp4" poster="img/poster.png" controls>
        <source data-src="/path/1600.mp4" data-mw="1600">
        <source data-src="/path/900.mp4" data-mw="900">
        <source data-src="/path/480.mp4" data-mw="480">
    </video>
     */

    options: {
        selector: string;
        breakpoints: { default: { src?: string | null } };
    }

    constructor(selector: string) {
        const $video = document.querySelector(selector) as HTMLVideoElement;
        $video.muted = true;
        this.options = {
            selector,
            breakpoints: {default: {src: $video?.getAttribute('data-src')}}
        };
        // get a list of video switching points and links to the videos themselves
        $video.querySelectorAll('[data-src]').forEach(element => {
            const breakpoint = element.getAttribute('data-mw');
            if (breakpoint) {
                this.options.breakpoints[breakpoint] = {src: element.getAttribute('data-src')};
            }
        });
        $video.innerHTML = ''; // we clean up so that there is nothing superfluous

        // run the handler and track the change in screen width
        this.responseVideo(this.options);
        this.resizer();
    }

    /** Function runs on resize  */
    resizer() {
        window.addEventListener("resize", () => this.responseVideo(this.options));
    }

    /**
     * Change src value of video link to fit screen width
     *
     * @param {Object} options object with options
     */
    responseVideo(options) {
        const {selector, breakpoints} = options; // get options
        const $video = document.querySelector(selector) as HTMLVideoElement;
        const width = document.body.clientWidth;
        const sizes = Object.keys(breakpoints).filter(key => key !== 'default').map(Number).filter(nr => nr <= width);
        let maxBreakpoint: string = 'default';
        if (sizes.length > 0) {
            maxBreakpoint = Math.max(...sizes).toString();
        }
        const widthNow = $video.getAttribute('data-width-now') || null;
        const nowBreakpoint = maxBreakpoint || 'default'; // choose either the maximum value, if not, then the default
        if (widthNow && widthNow == nowBreakpoint) return; // check if the video needs to be changed
        $video.setAttribute('data-width-now', nowBreakpoint);
        $video.src = breakpoints[nowBreakpoint].src;
    }
}
