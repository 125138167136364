import {getRandomRangeInt} from "./utils";

export function countUpAnimation(selectors: string) {
    const animateCountUp = (element) => {
        const frameDuration = 1000 / 60;
        const animationDuration = getRandomRangeInt(2000, 3000);
        const totalFrames = Math.round(animationDuration / frameDuration);
        // https://easings.net/
        const easeOutQuad = t => t * (2 - t);
        let frame = 0;
        const countToDataStringArray = (element.dataset.count || '').split('');
        const countToString = countToDataStringArray.filter(c => !isNaN(c)).join('');
        const countToUnit = countToDataStringArray.filter(c => isNaN(c)).join('');
        const countTo = Number(countToString) || 0;
        let lastCount = 0;
        const counter = setInterval(() => {
            frame++;
            const progress = easeOutQuad(frame / totalFrames);
            const currentCount = Math.round(countTo * progress);
            if (lastCount !== currentCount) {
                element.innerHTML = `${currentCount}${countToUnit}`;
            }
            lastCount = currentCount;
            if (frame === totalFrames) {
                clearInterval(counter);
            }
        }, frameDuration);
    };
    Array.from(document.querySelectorAll(selectors)).forEach(animateCountUp);
}
